import formatDate from '@comparacar/lib/src/utils/formatDate'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import React, { PropsWithChildren } from 'react'

const PriceWarning: React.FC<PropsWithChildren<{ updatedAt: string }>> = ({ updatedAt, children }) => {
  let formatedDate = formatDate(updatedAt, 'dd/MM/yyyy, pp')
  return (
    <Stack sx={{ gridColumn: 'auto' }} direction="row" spacing={0.5} alignItems="center">
      {children}
      <Tooltip
        disableFocusListener
        title={
          <>
            A ComparaCAR é um motor de busca, preços e condições podem mudar a qualquer momento e variar conforme o
            fornecedor. A ComparaCAR não se responsabiliza por divergências
            <br />
            {formatedDate && <> Atualizado em: {formatedDate} </>}
          </>
        }
      >
        <InfoOutlinedIcon sx={{ fontSize: '16px' }} />
      </Tooltip>
    </Stack>
  )
}

export default PriceWarning
