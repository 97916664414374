import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { styled, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSwipeable } from 'react-swipeable'

import ChipTransition from './ChipTransition'
import Image from './Image'
import CarPlaceholder from '../assets/CarPlaceholder'

interface iImageSlider {
  images: string[]
  title: string
  priority?: boolean
  link?: string
  tags?: tag[]
}

export const Button = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  color: theme.palette.common.white,
  cursor: 'pointer',
  width: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export const ImageBox = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: 'inherit',
  height: 'inherit',
  maxWidth: 'inherit',
  maxHeight: 'inherit',
  backgroundColor: 'none',
  display: 'block',
  overflow: 'hidden',
  '& > *': {
    objectFit: 'cover'
  }
})

export const ButtonStage = styled('div')({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  width: 'inherit',
  height: 'inherit',
  maxWidth: 'inherit',
  maxHeight: 'inherit'
})

const ImageSlider: React.FC<iImageSlider> = ({ tags, images, title, link, priority }) => {
  const [error, setError] = useState<boolean>(!images?.length)
  const [active, setActive] = useState<number>(0)
  const router = useRouter()
  const theme = useTheme()
  const sliderRef = useRef<HTMLDivElement | null>(null)

  const [dimension, setDimension] = useState<{ width: number; height: number }>({ width: 0, height: 0 })

  const prev = 0 < active ? active - 1 : null
  const next = active < images.length - 1 ? active + 1 : null

  const moveLeft = () => {
    if (next !== null) {
      setActive(next)
    }
  }

  const moveRight = () => {
    if (prev !== null) {
      setActive(prev)
    }
  }

  const handleOnClick = () => {
    if (link) router.push(link)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => moveLeft(),
    onSwipedRight: () => moveRight(),
    onTap: handleOnClick,
    trackMouse: true
  })

  const handleResize = useCallback(() => {
    if (sliderRef.current) {
      const boundRect = sliderRef.current.getBoundingClientRect()
      setDimension({
        width: boundRect.width || 0,
        height: boundRect.height || 0
      })
    }
  }, [sliderRef])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Box
      sx={{ width: 'inherit', height: 'inherit', maxWidth: 'inherit', maxHeight: 'inherit' }}
      ref={sliderRef}
      position="relative"
    >
      <ImageBox>
        {error ? (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: 'inherit',
              height: 'inherit',
              maxWidth: 'inherit',
              maxHeight: 'inherit',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CarPlaceholder />
          </div>
        ) : (
          <Image
            key={active}
            alt={`${title} ${active}`}
            width={dimension.width}
            height={dimension.height}
            src={images[active]}
            title={title}
            quality={75}
            priority={priority}
            onError={() => setError(true)}
            style={{ objectFit: 'contain' }}
          />
        )}
      </ImageBox>
      <ButtonStage>
        {prev !== null && (
          <Button onClick={moveRight}>
            <ArrowBackIosNewIcon />
          </Button>
        )}
        <div style={{ flex: 1, cursor: link ? 'pointer' : 'unset' }} {...handlers} />
        {next !== null && (
          <Button onClick={moveLeft}>
            <ArrowForwardIosIcon />
          </Button>
        )}
      </ButtonStage>
      <Box sx={{ position: 'absolute', top: theme.spacing(2), left: theme.spacing(2) }}>
        <ChipTransition tags={tags} />
      </Box>
    </Box>
  )
}

export default ImageSlider
